import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-presentation-ufr',
  templateUrl: './presentation-ufr.component.html',
  styleUrls: ['./presentation-ufr.component.less']
})
export class PresentationUFRComponent implements OnInit{

  ngOnInit(): void {
    AOS.init();
  }

}
