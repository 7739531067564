
<app-spinner></app-spinner>
<div class="content">
  <app-hero></app-hero>
  <div class="denonceAbout" >
    <h1 >
      Pourquoi est-il important de dénoncer les abus lorsqu’on est victime ou
      témoin ?
    </h1>
    <div class="homeHead" data-aos="fade-right">
      <div class="imgages">
        <img src="/assets/images/stoper-h.png" alt="" srcset="" />
      </div>
      <div
        class="text"
       
      >
        <p>
          Le harcèlement en milieu universitaire constitue un obstacle majeur entravant l’avenir et les relations entre enseignants, étudiants et Personnel Administratif et Technique, ou toute autre personne travaillant ou se trouvant sur l’espace universitaire.
        </p>
        <p>
          Il est impératif de mettre en place des mesures préventives et des
          programmes de sensibilisation pour éradiquer ce fléau. Les écoles
          doivent créer un environnement sûr et bienveillant, encourager la
          communication ouverte, et offrir un soutien psychologique adéquat aux
          victimes.
        </p>
      </div>
    </div>
  </div>

  <div class="lutte">
    <h1>Comment participer <span> à la lutte ?</span></h1>

    <div class="cards">
      <div class="card" data-aos="fade-right" data-aos-duration="750">
        <h3>Ecouter</h3>
        <p>
          Écouter sans juger ni blesser, et garder la confidentialité
        </p>
      </div>
      <div class="card" data-aos="fade-right" data-aos-duration="1500">
        <h3>Agir</h3>
        <p>
          Agir avec prudence pour que personne n’encourt aucun danger, mais en prenant vos responsabilités

        </p>
      </div>
      <div class="card" data-aos="fade-right" data-aos-duration="2250">
        <h3>Sensibiliser</h3>
        <p>
          sensibiliser et encourager votre entourage à lutter activement contre
          ce fléau, prenez l'initiative de parler ouvertement du problème.
        </p>
      </div>
      <div class="card" data-aos="fade-right" data-aos-duration="3000">
        <h3>Condamner</h3>
        <p>
          Condamnez les agresseurs en partageant vos témoignages tout en
          participant aux appels à témoins ou en les dénonçant de manière
          anonyme.
        </p>
      </div>
    </div>
  </div>

  <div class="join">
    <div class="blocImage">
      <div class="goupImg mb-1">
        <div class="range d-flfex">
          <img class="mr-1" src="/assets/images/poles.png" alt="" />
          <img src="/assets/images/poles.png" alt="" />
        </div>
      </div>
      <img class="longImg" src="/assets/images/partage.png" alt="" />
    </div>
    <div class="contentText">
      <h4>Rejoignez notre communauté</h4>
      <h3>
        Partagez votre histoire avec nous pour éviter que d’autres personnes ne vivent la même expérience.
      </h3>
      <p>
        Ensemble, brisons le silence sur les abus dans le milieu universitaire.
        Votre témoignage peut être la clé pour mettre fin à ces pratiques
        inacceptables et assurer un environnement d'apprentissage sûr et
        équitable pour tous. Partagez votre histoire avec nous pour que nous
        puissions faire entendre votre voix et prendre des mesures pour mettre
        fin à ces abus. Votre expérience peut inspirer le changement et protéger
        les futures générations d'étudiants.
      </p>
    </div>
  </div>

  <div class="slider">
    <h5>Vous pouvez lutter <span> à votre manière</span></h5>
    <h3>Sensibilisez et dénoncer</h3>
    <div class="slide mb-5" id="mt-3">
      <app-app-slider [images]="images"></app-app-slider>
    </div>

    <h5>Des conseils auprès <span>de nos cellules</span></h5>

    <div class="cellule" data-aos="zoom-in" data-aos-duration="2000">
      <div class="text-celleule">
        <!-- <h3 class="mb-3">Des conseils auprès de nos cellules</h3> -->
       <p> &ndash;  Saisir, réception et enregistrement des plaintes </p>
       <p> &ndash;  Traitement et analyse des plaintes </p>
       <p> &ndash;  Enquête et vérification des preuves relatives à la plainte </p>
       <p> &ndash;  Réponse </p>
       <p> &ndash;  Révision des réponses en cas de non-résolution en première instance   </p>
       <p> &ndash;  Mise en œuvre des mesures correctrices</p>
       <p> &ndash;  Suivi et clôture </p>
      </div>
      <div class="imgCellule">
        <img src="/assets/images/cellule.png" alt="" />
      </div>
    </div>
  </div>

  <!-- section blog -->

  <div class="blogs">
    <h5 class="blogTitre" data-aos="fade-up" data-aos-duration="2500">
      Messages de sensibilisation publiés récemment <br />
      sur notre blog
    </h5>

    <div class="cartes">
      <div
        class="carte"
        *ngFor="let items of cardBlog"
        data-aos="zoom-in-down"
        data-aos-duration="3000"
      >
        <img [src]="items.image" alt="" />
        <div class="desc">
          <p class="date">{{ items.date }}</p>
          <h6>{{ items.title }}</h6>
          <p>{{ items.description }}</p>
          <p>{{ items.description2 }}</p>
          <p>{{ items.description3 }}</p>
          <p>{{ items.description4 }}</p>
          <p>{{ items.description5 }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- finSectionBLog -->

  <!-- section main -->

  <div class="sectionMain">
    <h5 class="cons" >
      Ne laissons plus nos proches <span>en souffrir !</span>
    </h5>

    <div class="main">
      <div class="imgGroup" >
        <img
          class=""
          src="/assets/images/groups.png"
          alt="image equipe"
          srcset=""
        />
      </div>
      <div class="textMain" >
        <p>Dénoncer quelqu'un</p>
        <h3>Reconnais-tu quelqu'un dans cette situation ?</h3>
        <p>
          Dénoncer un cas de harcèlement est une étape cruciale pour mettre fin à l'insécurité sur l'espace universitaire. Dans un premier temps, parlez-en à
          quelqu'un de confiance, contactez les autorités universitaires, rassemblez des preuves et suivre les procédures de signalement de l'université. Pour cela, utilisez l'application sans craindre les représailles.
          
        </p>
        <div class="sign-btns" >
          <button type="button">Fait ta part</button>
        </div>
      </div>
    </div>
  </div> 

  <!-- finSectionMain -->

  <!-- debut asside -->

  <div class="asside">
    <!-- <p class="titre" data-aos="fade-up"
        data-aos-duration="1000">lorem </p> -->
    <h2 data-aos="fade-up" data-aos-duration="1500">
      Le harcèlement en ligne augmente d'une façon significative <br />
      dans le milieu universitaire.
    </h2>
    <p data-aos="fade-up" data-aos-duration="2000" class="text-justify">
     Attention: <br />
      <br />
      Le harcèlement à l'université est un problème sérieux et préoccupant qui
      peut prendre de nombreuses formes. Il peut affecter les étudiants, les
      enseignants, le personnel administratif et d'autres membres de la
      communauté universitaire. <br />
      <br />
      Le harcèlement à l'université peut avoir des conséquences graves sur la
      santé mentale et le bien-être des victimes. Les universités prennent
      généralement ces questions très au sérieux et ont des politiques en place
      pour prévenir et répondre au harcèlement
    </p>
    <div class="sign-btns" data-aos="fade-up" data-aos-duration="2500">
      <button type="button">Dénonce maintenant</button>
    </div>
  </div>

  <!-- fin asside -->
</div>



<!-- <div>
  <app-spinner></app-spinner>
</div> -->
