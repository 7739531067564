import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos'

@Component({
  selector: 'app-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.less']
})
export class DonationComponent implements OnInit {

  ngOnInit(): void {
    AOS.init();
  }

}
