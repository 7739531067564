
<app-spinner></app-spinner>

<div class="lutteContent">
    <app-titre

    [preTitle]="'Comment'"
    [title]="'lutter'"
    [description]="'Lutter pour résoudre les problèmes qui vous affectent demande de l\'engagement, de la persévérance, et un sens de l\'action. Voici plusieurs façons de vous impliquer activement dans la recherche de solutions :   Communiquez avec nos services : Lorsque vous êtes confronté à un problème, la première étape consiste à en parler. Vous n\'êtes pas seul, et il est essentiel de partager vos préoccupations. Appelez nos services, où notre équipe est là pour vous écouter, comprendre vos problèmes, et vous fournir des conseils. La communication est la première étape pour sensibiliser aux problèmes.Utilisez notre application mobile pour déposer des plaintesanonymes.'">

    </app-titre>

    <!-- <div class="head"  data-aos="fade-down" data-aos-duration="2000">
            
        <div class="titre">
            <h3>Comment lutter contre les harcèlements </h3>
        </div>

        <p class="textHeaed">Lorem ipsum dolor sit amet consectetur. Integer ultricies lacus semper viverra mauris sed at sit. Velit vitae non turpis amet laoreet lacus nulla mattis vitae. Tortor sit porttitor ut eget quis feugiat.Lorem ipsum dolor sit </p>
    </div> -->

    <div class="cards mt-5">
        <div class="card" *ngFor="let item of listLutte" data-aos="fade-up" data-aos-duration="2000">
            <div class="numbTitre">
                <div class="num">
                    <p class="pt-1"> {{item.numero}} </p>
                </div>
                <h5 class="ml-4 mt-1"> {{item.titre}} </h5>
            </div>
            <p class="mt-4 text-center"> {{item.description}} </p>
        </div>
    </div>

    <div class="aide">
        <div class="text" data-aos="fade-right" data-aos-duration="1000">
            <h4>Besoin d'aide ?</h4>
            <p>Vos témoignages sont des preuves tangibles qui peuvent conduire à des enquêtes et des réformes. Votre voix a le pouvoir de faire la différence.Recevez des mises à jour sur les actions prises pour résoudre les problèmes que vous signalez. Soyez au courant des avancées et des changements positifs</p>
        </div>
        <div class="buttons" >
            <div class="home">
                <button type="button">Télecharger l’application</button>
            </div>
        </div>
    </div>

</div>
