import {Component} from '@angular/core';

@Component({
    selector: 'app-forgot-password-layout',
    templateUrl: './forgot-password-layout.component.html',
    styleUrls: ['./forgot-password-layout.component.less']
})
export class ForgotPasswordLayoutComponent {

}
