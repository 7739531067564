

<app-spinner></app-spinner>
<div class="actionPage">
  <app-titre
    [preTitle]="'Nos'"
    [title]="'actions'"
    [description]="
      'Les étapes que nous suivons pour traiter les cas sont les suivantes : enregistrement du cas, analyse, traitement interne, communication, établissement d\'un procès-verbal, et enfin la prise de décision finale'
    "
  >
  </app-titre>

  <div class="slider-container">
    <app-sliders-actions></app-sliders-actions>
  </div>

  <div
    class="sensibilisation mt-5"
   
  >
    <h3>Sensibilisations & préventions</h3>
    <p class="para">
      Le partage de votre histoire peut sensibiliser les autres aux abus. Votre
      voix peut contribuer à prévenir d'autres incidents et à aider ceux qui ont
      vécu des expériences similaires.
      <br />
    </p>

    <div class="consequence mt-3">
      <h2>Causes & consequences</h2>
      <div class="groupCause">
        <div class="img">
          <img src="/assets/images/actions/actions.png" alt="" />
        </div>
        <div class="contentText">
          <h4>Libérez-vous du Silence : Partagez Vos Expériences</h4>
          <p>
            Lorsque vous subissez des abus, il peut être incroyablement
            difficile de trouver la force de partager votre histoire. Vous vous
            sentez peut-être isolé, honteux, ou effrayé des conséquences.
            Pourtant, garder le silence face aux abus ne fait qu'aggraver la
            situation. Il est temps de briser ce cycle destructeur en vous
            exprimant, en partageant vos expériences, et en recherchant du
            soutien. Dans cet article, nous explorons pourquoi il est crucial de
            ne plus garder les abus vécus pour vous, comment partager votre
            histoire peut favoriser la guérison, et où trouver l'aide dont
            vous avez besoin
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="listen">
    <h3 data-aos="fade-up" data-aos-duration="1000">L’écoute</h3>
    <p class="para" data-aos="fade-up" data-aos-duration="1500">
      L'écoute joue un rôle fondamental pour amorcer le processus de suivi
      de votre plainte
    </p>

    <div class="carte">
      <div class="listenText" data-aos="fade-right" data-aos-duration="2000">
        <h2>
          Plateforme d'Écoute et de Soutien aux Étudiants : <br />
          Donner la Voix à la Parole Silencieuse
        </h2>
        <p>
          L L'expérience universitaire devrait être une période de croissance
          personnelle, d'apprentissage et de développement, mais
          malheureusement, de nombreux étudiants sont confrontés à des abus, à
          des comportements inappropriés de la part de professeurs et de
          collègues, et à un climat de peur et d'insécurité. Pour répondre à ces
          défis, nous avons créé une plateforme d'écoute et de soutien dédiée à
          donner la voix à la parole silencieuse des étudiants. Notre mission
          est de fournir un espace sûr et confidentiel où les étudiants peuvent
          partager leurs expériences, dénoncer les abus, et obtenir le soutien
          nécessaire pour promouvoir un environnement académique respectueux et
          inclusif. Dans cet article, nous explorerons en profondeur cette
          plateforme innovante et ses multiples facettes. Donner la Voix à la
          Parole Silencieuse
        </p>
      </div>
      <div class="imageListen" data-aos="fade-left" data-aos-duration="2000">
        <img src="/assets/images/actions/listen.png" alt="" />
      </div>
    </div>

    <div class="statistique" data-aos="fade-up" data-aos-duration="2500">
      <div class="cards">
        <h5>+200</h5>
        <p>Personnes écoutées</p>
      </div>
      <div class="cards">
        <h5>+150</h5>
        <p>Demandes traités</p>
      </div>
    </div>
  </div>

  <div class="sanctions">
    <h3>Les sanctions</h3>
    <p class="para mb-5">
      De nombreuses sanctions sont prévues pour les contrevenants en
      vue de les punir.
    </p>

    <div class="cards">
      <div class="card" data-aos="zoom-in" data-aos-duration="2600">
        Avertissement
      </div>
      <div class="card" data-aos="zoom-in" data-aos-duration="1000">
        Les amendes
      </div>
      <div class="card" data-aos="zoom-in" data-aos-duration="1200">
        La prison
      </div>
      <div class="card" data-aos="zoom-in" data-aos-duration="1400">
        Le licenciement
      </div>
      <div class="card" data-aos="zoom-in" data-aos-duration="1600">
        Les mises à pied
      </div>
      <div class="card" data-aos="zoom-in" data-aos-duration="2000">
        La révocation
      </div>
    </div>
  </div>
</div>
