<div class="sidebar-wrapper">
  <div class="logo">
    <div class="content-logo-app card-logo-app">
      <img
        [src]="'/assets/images/logo-app.png'"
        alt="CAEGP"
        class="h-100 w-100"
      />
    </div>
    <!--
        <a class="simple-text logo-mini" routerLink="/dashboard">
          <div class="logo-image-small"></div>
        </a>-->
  </div>
  <ul
    [nzInlineCollapsed]="false"
    class="nav"
    nz-menu
    nzMode="inline"
    nzTheme="light"
  >
    <ng-container *ngFor="let menuItem of menuItems">
      <li
        *ngIf="menuItem.children.length == 0"
        [nzSelected]="activatedLinkChecker(menuItem.path)"
        class="icon-sidebar"
        nz-menu-item
        routerLink="{{ menuItem.path }}"
        routerLinkActive="active"
      >
        <span nz-icon nzType="{{ menuItem.icon }}"></span>
        <span>{{ menuItem.name }}</span>
      </li>
      <li
        *ngIf="menuItem.children.length > 0"
        nz-submenu
        nzIcon="{{ menuItem.icon }}"
        nzTitle="{{ menuItem.name }}"
      >
        <ul *ngIf="menuItem.children.length > 0">
          <li
            *ngFor="let childItem of menuItem.children"
            [nzSelected]="activatedLinkChecker(childItem.path)"
            class="icon-sidebar"
            nz-menu-item
            routerLink="{{ childItem.path }}"
            routerLinkActive="{{ activatedLinkChecker(childItem.path) }}"
          >
            {{ childItem.name }}
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <div class="footer-menu">
    <div class="content-illustration-setting">
      <div class="content-img">
        <img alt="" src="/assets/images/svg/setting.svg" />
      </div>
      <button
        nz-button
        nzBlock
        nzSize="small"
        (click)="goParameter()"
        nzType="primary"
      >
        <span nz-icon nzTheme="outline" nzType="setting"></span>
        Paramètres
      </button>
    </div>

    <button
      (click)="onLogOut()"
      class="mt-3"
      nz-button
      nzBlock
      nzSize="small"
      nzType="text"
    >
      <span nz-icon nzTheme="outline" nzType="logout"></span>
      Se déconnecter
    </button>
  </div>
</div>
