import { Component } from '@angular/core';
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.less']
})
export class HeroComponent {

  slides = [
    {
      img: "/assets/images/fond1.png",
      texte:' Bienvenue sur le portail de la Cellule Autonome D’Écoute et de Gestion des Plaintes (CAEGP) de l’Université Félix Houphouët Boigny (UFHB)'
    },
    {
      img: "/assets/images/fond1.png",
      texte:'Le harcèlement sexuel et moral dans l’enseignement supérieur, il y a ça chez nous ! '
    },
    // {
    //   img: "/assets/images/fond1.png",
    //   texte:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum modi atque deleniti sapiente architecto ullam hic quo molestiae ipsam quam ipsa, et voluptatem reprehenderit '
    // },
    // {
    //   img: "/assets/images/fond1.png",
    //   texte:'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum modi atque deleniti sapiente architecto ullam hic quo molestiae ipsam quam ipsa, et voluptatem reprehenderit '
    // },
  ];
  slideConfig = {"slidesToShow": 1,
   "slidesToScroll": 1,
    dots:true ,
     autoplay:true,
     autoplaySpeed:4000,
     infinite:true,
};

  ACTIONS = [
    {
      img: "/assets/images/fond1.png",
      title: "Le danger de l’harcèlement",
      alt:'',
      description:
        "Le fait de garder pour vous le fait que vous subissez des abus peut avoir des effets néfastes sur votre santé mentale.",
    },
    {
      img: "/assets/images/fond1.png",
      title: "Le risque sur La santé  mentale",
      description:
        "Le fait de garder pour vous le fait que vous subissez des abus peut avoir des effets néfastes sur votre santé mentale.",
    },
    {
      img: "/assets/images/fond1.png",
      title: "Savoir dénoncer",
      description: "Vous devez dénoncer les abus subis.",
    },
  ];


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-arrow-left custom-prev"></i>',
      '<i class="fa fa-arrow-right custom-next" ></i>',
    ],
    items: 2,
    responsive: {
      0: {
        items: 1,
      },

      768: {
        items: 3,
      },
    },
    nav: true,
  };
}
