import { Component } from "@angular/core";
import { mappingRoutes } from "../../core/mappingRoutes";

export const ROUTES: any[] = [
  {
    path: mappingRoutes.dashboard,
    name: "Accueil",
    title: "Dashboard",
    icon: "windows",
    prefix: "",
    class: "",
    children: [],
  },
  {
    path: mappingRoutes.statistique,
    name: "Statistiques",
    title: "Statistiques",
    icon: "pie-chart",
    prefix: "metric",
    class: "",
    children: [],
  },
  {
    path: "#",
    prefix: "complaints",
    title: "Gestion des plaintes",
    name: "Plaintes",
    icon: "alert",
    class: "",
    children: [
      {
        path: mappingRoutes.plaintes.listing,
        name: "Liste ",
        class: "",
      },
      {
        path: mappingRoutes.plaintes.create,
        name: "Ajouter ",
        class: "",
      },
    ],
  },

  {
    path: mappingRoutes.proceVerbal.allPv,
    name: "Procès-verbal",
    title: "Procès verbal",
    icon: "file-done",
    prefix: "pv",
    class: "",
    children: [],
  },

  {
    path: mappingRoutes.notification,
    name: "Notifications",
    title: "Notifications",
    icon: "bell",
    prefix: "metric",
    class: "",
    children: [],
  },

  {
    path: "#",
    prefix: "notify",
    title: "Gestions des messages",
    name: "Communication",
    icon: "message",
    class: "",
    children: [
      {
        path: mappingRoutes.messageManagements.reportTransmission.list,
        name: "Transmission des rapport",
        class: "",
      },
      {
        path: mappingRoutes.messageManagements.messageAwareness.listing,
        name: "sensibilisation",
        class: "",
      },
      {
        path: mappingRoutes.messageManagements.appelTemoins,
        name: "Appel à témoins",
        class: "",
      },
    ],
  },

  {
    path: mappingRoutes.archives.index,
    name: "archives",
    title: "Archivage",
    icon: "compress",
    prefix: "metric",
    class: "",
    children: [],
  },

  {
    path: "#",
    prefix: "administrateurs",
    title: "Gestion des administrateurs",
    name: "administrateurs",
    icon: "control",
    class: "",
    children: [
      {
        path: mappingRoutes.administrateurs.listing,
        name: "Liste des administrateurs",
        class: "",
      },
      // {
      //   path: mappingRoutes.administrateurs.create,
      //   name: "Ajouter un administrateur",
      //   class: "",
      // },
    ],
  },
  {
    path: "#",
    prefix: "security_habilitation",
    title: "Sécurité_habilitations",
    name: "Sécurité & habilitations",
    icon: "key",
    class: "",
    children: [
      {
        path: mappingRoutes.profiles.listing,
        name: "Profils",
        class: "",
      },
      {
        path: mappingRoutes.permissions.listing,
        name: "Permissions",
        class: "",
      },
    ],
  },

  {
    path: "#",
    prefix: "configurations",
    title: "Configurations",
    name: "Configurations",
    icon: "appstore",
    class: "",
    children: [
      {
        path: mappingRoutes.categoriesPlaintes.listing,
        name: "Categorie de plainte ",
        class: "",
      },
      {
        path: mappingRoutes.systemGestionPlaintes.listing,
        name: "Système de gestion de plainte ",
        class: "",
      },

      {
        path: mappingRoutes.levelsTraitementPlaintes.listing,
        name: "Niveau de traitement",
        class: "",
      },
    ],
  },
];

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.less"],
})
export class DashboardLayoutComponent {
  get menuSidebar() {
    return ROUTES;
  }
}
