import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { mappingRoutes } from "src/app/core/mappingRoutes";

@Component({
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["./navbar.component.less"],
})
export class NavbarComponent implements OnInit {
  @Input("routeMenu") ROUTE_MENU: any[] = [];
  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button: any;
  titleModule: string = "";
  private listTitles: any[] = [];
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.listTitles = this.ROUTE_MENU.filter((listTitle: any) => listTitle);
    this.titleModule = this.listTitles[0]["title"];
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
      this.getTitleMenu();
    });
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    //console.log(navbar);
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }

  getTitleMenu() {
    const titles = this.listTitles.filter((value, index) =>
      this.router.url.includes(value["prefix"])
    );
    console.log(`titles ${titles}`);
    this.titleModule =
      titles.reverse()[0]["title"] ?? this.listTitles[0]["title"];
  }

  goProfile() {
    this.router.navigate([mappingRoutes.myProfile]);
  }
  goNotification() {
    this.router.navigate([mappingRoutes.notification]);
  }
}
