import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthLayoutComponent } from "./auth-layout/auth-layout.component";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FixedPluginComponent } from "./fixedplugin/fixedplugin.component";
import {
  RouterLink,
  RouterLinkActive,
  RouterModule,
  RouterOutlet,
} from "@angular/router";
import { NzMenuModule } from "ng-zorro-antd/menu";
import { NzIconModule } from "ng-zorro-antd/icon";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NzInputModule } from "ng-zorro-antd/input";
import { ForgotPasswordLayoutComponent } from "./forgot-password-layout/forgot-password-layout.component";
import { DashboardLayoutComponent } from "./dashboard-layout/dashboard-layout.component";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzAvatarModule } from "ng-zorro-antd/avatar";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { NzBreadCrumbModule } from "ng-zorro-antd/breadcrumb";
import { LayoutPortalComponent } from "./layout-portal/layout-portal.component";
import { NzDrawerModule } from "ng-zorro-antd/drawer";
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SidebarComponent } from "./footer/sidebar/sidebar.component";
import { PortalHeaderComponent } from './layout-portal/components/portal-header/portal-header.component';
import { PortalFooterComponent } from './layout-portal/components/portal-footer/portal-footer.component';
@NgModule({
  declarations: [
    AuthLayoutComponent,
    ForgotPasswordLayoutComponent,
    FooterComponent,
    NavbarComponent,
    FixedPluginComponent,
    DashboardLayoutComponent,
    LayoutPortalComponent,
    SidebarComponent,
    PortalHeaderComponent,
    PortalFooterComponent
  ],

  imports: [
    CommonModule,
    RouterOutlet,
    RouterLink,
    NzMenuModule,
    RouterLinkActive,
    NzIconModule,
    NgbModule,
    NzInputModule,
    NzButtonModule,
    NzAvatarModule,
    NzSpaceModule,
    NzBreadCrumbModule,
    RouterModule,
    NzDrawerModule,
    NzDropDownModule ,
    SlickCarouselModule,
  ],
  exports: [
    ForgotPasswordLayoutComponent,
    AuthLayoutComponent,
    FixedPluginComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LayoutPortalComponent,
    
  ],
})
export class LayoutsModule {}
