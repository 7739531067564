<div class="">
<ngx-slick-carousel class="carousel"  #slickModal="slick-carousel"   [config]="slideConfig" >
<div ngxSlickItem *ngFor="let slide of slides" class="contentHome">
<img src="{{ slide.img }}" alt="" height="100%" width="100%">

  <div class="text">
    <h1> {{slide.texte}} </h1>
    <div class="home">
      <button type="button">Nous soutenir</button>
  </div>
  </div>
</div>
</ngx-slick-carousel>
   
</div>

<!-- <img class="imgHome" src="/assets/images/fond1.png" alt="">

<div class="text">
<h1>
    Bienvenu sur le portail de Cellule  Autonome d'Ecoute <br> et de Gestion des Plaintes (CAEGP) de <br> l'Université Félix Houphouët Boigny
</h1>
<div class="home">
    <button type="button">Nous soutenir</button>
</div>
</div> -->
<!-- <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of ACTIONS; let i = index">
      <ng-template carouselSlide [id]="'id_slide_action_' + i">
        <div class="card">
          <div
            class="image_preview"
            [ngStyle]="{ 'background-image': 'url(' + slide.img + ')' }"
          ></div>
          <div class="title">{{ slide.title }}</div>
          <div class="desc">{{ slide.description }}</div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o> -->


<!-- <h1>
    Bienvenu sur le portail de Cellule  Autonome d'Ecoute et de Gestion des Plaintes (CAEGP) de l'Université Félix Houphouët Boigny
</h1>
<div class="home">
    <button type="button">Nous soutenir</button>
</div> -->

<!-- <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of ACTIONS; let i = index">
      <ng-template carouselSlide [id]="'id_slide_action_' + i">
        <div class="text">
          <div
            class="image_preview"
            [ngStyle]="{ 'background-image': 'url(' + slide.img + ')' }"
          ></div>
          <div class="title">{{ slide.title }}</div>
          <div class="desc">{{ slide.description }}</div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o> -->