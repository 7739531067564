
<app-spinner></app-spinner>
<div class="contentDonantion">
  <!-- <div class="titre" data-aos="fade-down" data-aos-duration="2000">
        <h3>FAIRE UN DON POUR SOUTENIR LA LUTTE </h3>
    </div> -->
  <app-titre [preTitle]="'Faire'" [title]="'un don'" [description]="''">
  </app-titre>

  <div class="cards mt-5" data-aos="fade-left" data-aos-duration="3000">
    <div class="amounth">
      <div class="choice">
        <p>choisir le montant</p>
        <div class="cartes">
          <button class="carte">100 000 f</button>
          <button class="carte">200 000 f</button>
          <button class="carte2">500 000 f</button>
        </div>
      </div>
      <div>
        <p class="separateur">OU</p>
        <hr class="trait" />
      </div>
      <div class="write">
        <p>choisir le montant</p>
        <form class="formMonatnt">
          <input type="text" required placeholder="Exp: 1 500 000 " />
        </form>
      </div>
    </div>

     <div class="place">
      <div class="addresse">
        <form class="form">
          <label for="addresse">Adresse</label>
          <div class="formGroup">
            <input type="text" placeholder="Adresse ligne 1" />
          </div>
          <div class="formGroup">
            <input type="text" placeholder="Adresse ligne 2" />
          </div>
          <div class="formGroup">
            <input type="text" placeholder="ville" />
          </div>
          <div class="formGroup">
            <input type="text" placeholder="Pays" />
          </div>
        </form>
      </div>
      <div>
        <hr class="hr" />
      </div>
      <div class="email">
        <form class="form">
          <label for="addresse">Entrer votre adresse email</label>
          <div class="formGroup">
            <input type="text" placeholder="Adresse ligne 1" />
          </div>

          <label for="addresse">Faire une donation</label>
          <div class="">
            <input type="checkbox" name="my-checkbox" id="opt-in" />
            <span class="textCheck"
              >J’aimerai avoir des informations par email sur <br />
              l’utilisation de ma donation</span
            >
          </div>
        </form>
      </div>
    </div> 

    <div class="btnsText">
      <div class="btns">
        <button class="btn1" type="button">Ma Carte</button>
        <button class="btn2" type="buttons">Soumettre</button>
      </div>
    </div>
  </div>
</div>
