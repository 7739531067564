import { Component } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-sliders-actions",
  templateUrl: "./sliders-actions.component.html",
  styleUrls: ["./sliders-actions.component.less"],
})
export class SlidersActionsComponent {
  ACTIONS = [
    {
      img: "/assets/images/actions/sexual-harassment.svg",
      title: "Le danger de l’harcèlement",
      description:
        "Le fait de garder pour vous le fait que vous subissez des abus peut avoir des effets néfastes sur votre santé mentale.",
    },
    {
      img: "/assets/images/actions/mental-health.svg",
      title: "Le risque sur La santé  mentale",
      description:
        "Le fait de garder pour vous le fait que vous subissez des abus peut avoir des effets néfastes sur votre santé mentale.",
    },
    {
      img: "/assets/images/actions/surrounding.svg",
      title: "Savoir dénoncer",
      description: "Vous devez dénoncer les abus subis.",
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-arrow-left custom-prev"></i>',
      '<i class="fa fa-arrow-right custom-next" ></i>',
    ],
    items: 2,
    responsive: {
      0: {
        items: 1,
      },

      768: {
        items: 3,
      },
    },
    nav: true,
  };
}
