import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";


@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.less"],
})
export class HomeComponent implements OnInit {
  ngOnInit(): void {
    AOS.init();
  }

  // constructor(private spinner: NgxSpinnerService){}



  images = [
    {
      imageSrc: "/assets/images/slides1.png",
      imageAlt: "slide1",
    },
    {
      imageSrc: "/assets/images/slides3.png",
      imageAlt: "slide2",
    },
    {
      imageSrc: "/assets/images/slides6.jpg",
      imageAlt: "slide3",
    },
    {
      imageSrc: "/assets/images/slides7.PNG",
      imageAlt: "slide2",
    },
    {
      imageSrc: "/assets/images/slides8.jpeg",
      imageAlt: "slide1",
    },
  ];

  cardBlog: readonly any[] = [
    {
      image: "/assets/images/sexual_harasment.jpg",
      date: "17 Oct. 2023",
      title: "Harcèlement sexuel",
      description:" - Le harcèlement sexuel est puni par la loi",
      description2:" - Sans oui, c’est non !",
    },
    {
      image: "/assets/images/morale.jpg",
      date: "16 Oct. 2023",
      title: "Harcèlement moral ",
      description:" - L’injustice",
      description2:" - Les insultes",
      description3:" - Les moqueries",
      description4:" - Les menaces",
    },
    {
      image: "/assets/images/harcelement-moral-scaled.jpg",
      date: "15 Oct. 2023",
      title: "Harcèlement discriminatoire",
      description:" - La race",
      description2:" - L’origine ethnique",
      description3:" - La religion",
      description4:" - L’orientation sexuelle",
      description5:" - Le handicap",
    },
    {
      image: "/assets/images/academique.png",
      date: "14 Oct. 2023",
      title: "Harcèlement académique",
      description:" - Le manque de respect",
      description2:" - L’indiscipline",
    },
    {
      image: "/assets/images/violence.jpg",
      date: "14 Oct. 2023",
      title: "Violence basée sur le genre",
      description:" - Violence sexuelle",
      description2:" - Discrimination à l'école",
      description3:" - Stigmatisation sociale ",
    },
  ];
}
