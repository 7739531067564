<div class="groups">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of ACTIONS; let i = index">
      <ng-template carouselSlide [id]="'id_slide_action_' + i">
        <div class="card">
          <div
            class="image_preview"
            [ngStyle]="{ 'background-image': 'url(' + slide.img + ')' }"
          ></div>
          <div class="title">{{ slide.title }}</div>
          <div class="desc">{{ slide.description }}</div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
