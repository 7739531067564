import {Routes} from '@angular/router';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {ForgotPasswordLayoutComponent} from "./layouts/forgot-password-layout/forgot-password-layout.component";
import {DashboardLayoutComponent} from "./layouts/dashboard-layout/dashboard-layout.component";
import { LayoutPortalComponent } from './layouts/layout-portal/layout-portal.component';


export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'portal',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        loadChildren: () => import('./pages/auth/auth.module').then(x => x.AuthModule)
    },
    {
        path: 'forgot',
        component: ForgotPasswordLayoutComponent,
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(x => x.ForgotPasswordModule)
    },
    {
        path: 'portal',
        component: LayoutPortalComponent,
        loadChildren: () => import('./pages/portal/portal.module').then(x => x.PortalModule)
    },
    
    
    {
        path: 'administration',
        component: DashboardLayoutComponent,
        data: {
            breadcrumb: 'Administration ',
        },
        loadChildren: () => import("./pages/administration/administration.module").then(x => x.AdministrationModule)
    },

    {
        path: '**',
        redirectTo: 'portal',
    }
]
