import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { mappingRoutes } from "src/app/core/mappingRoutes";


@Component({
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
  styleUrls: ["./sidebar.component.less"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = [];
  @Input("routeMenu") ROUTE_MENU: any[] = [];

  constructor(private router: Router) {}

  ngOnInit() {
    this.menuItems = this.ROUTE_MENU.filter((menuItem) => menuItem);
  }

  public activatedLinkChecker = (link: string): boolean =>
    this.router.url.includes(link);

  redirecToIndex() {
    this.router.navigate([this.ROUTE_MENU[0]["path"]]);
  }

  onLogOut() {
    this.router.navigate([mappingRoutes.start]);
  }

  goArchive() {
    this.router.navigate([mappingRoutes.archives]);
  }
  goParameter() {
    this.router.navigate([mappingRoutes.parameter]);
  }
}
