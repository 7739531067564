<footer class="container-footer">
    <div class="linksFooter">
      <ul>
        <h6>Pages</h6>
        <li class="cursor-pointer" (click)="goToHome()">
          <i nz-icon nzType="right" nzTheme="outline"></i>
          Notre mission
        </li>
        <li class="cursor-pointer" (click)="goToAction()">
          <i nz-icon nzType="right" nzTheme="outline"></i>
          Nos actions
        </li>
        <li class="cursor-pointer" (click)="goToLutte()">
          <i nz-icon nzType="right" nzTheme="outline"></i>
          Comment lutter
        </li>
        <!-- <li><i nz-icon nzType="right" nzTheme="outline"></i> Conseils</li> -->
        <!-- <li><i nz-icon nzType="right" nzTheme="outline"></i> A Propos</li> -->
      </ul>
      <ul>
        <h6>Besoin d’aide ?</h6>
        <li>
          <i nz-icon nzType="right" nzTheme="outline"></i> Télécharger notre
          application mobile
        </li>
        <h6>Combattez maintenant</h6>
      </ul>
      <ul>
        <h6>Envie de nous soutenir</h6>
        <p>
          Contactez nous par email et <br />
          ensemble tentons de mettre un <br />
          terme aux abus
        </p>
        <div class="sign-btns">
          <button type="button">Nous contacter</button>
        </div>
      </ul>
      <ul>
        <h6>Rejoindre notre newsletter</h6>
        <form>
          <div class="groupForm">
            <label for="name">Nom</label>
            <input type="text" required maxlength="20" />
          </div>
          <div class="groupForm">
            <label for="lastname">Prénom(s)</label>
            <input type="text" required maxlength="80" />
          </div>
          <div class="groupForm">
            <label for="email">Email</label>
            <input type="email" required maxlength="80" />
          </div>
          <div class="groupForm">
            <label for="code">ZIP Code</label>
            <input type="number" required maxlength="10" />
          </div>
          <div class="sign-btns">
            <button type="button">Nous Réjoindre</button>
          </div>
        </form>
      </ul>
    </div>
    
   <div class="parteners">
    <h5>Nos partenaires</h5> <br>
    <ngx-slick-carousel class="carousel"  #slickModal="slick-carousel"   [config]="slideConfig" >
      <div ngxSlickItem *ngFor="let item of Images" class="carousseles">

        <div><img class="partenaireImg" [src]="item.src" alt=""></div>

        <div><p class="desc"> {{item.name}} </p></div>
          <!-- <div class="flexible">
            <div class="groupParetenaire" *ngFor="let item of Images">
              <div class="ronde">
                <div><img class="partenaireImg" [src]="item.src" alt=""></div>
            </div>
        
            <div><p class="desc"> {{item.name}} </p></div>
            </div>
          </div> -->
        
      </div>
      </ngx-slick-carousel>
   </div>
    <div class="basPage">
      <a href="#" class="nav-icon" aria-label="homepage" aria-current="page">
        <img src="/assets/images/logo-app.svg" alt="chat icon" />
      </a>
      <p>Tous les droits réservés 2023 ©</p>
      <div class="social">
        <a href=""> <img src="/assets/images/svg/Vector.svg" alt="" /></a>
        <a href=""> <img src="/assets/images/svg/twitter.svg" alt="" /></a>
        <a href=""> <img src="/assets/images/svg/insta.svg" alt="" /></a>
      </div>
    </div>
  </footer>
  