
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-lutte-page',
  templateUrl: './lutte-page.component.html',
  styleUrls: ['./lutte-page.component.less']
})
export class LuttePageComponent implements OnInit {

  ngOnInit(): void {
    AOS.init();
  }

  listLutte: readonly any[] = [
    {
      numero: "1",
      titre: "Se confier",
      description:
        "Trouvez un espace pour vous confier et partager vos expériences.",
    },
    {
      numero: "2",
      titre: "Se protéger",
      description:
        "Apprenez comment vous protéger contre les abus et les injustices.",
    },
    {
      numero: "3",
      titre: "Signaler un abus",
      description:
        "Découvrez comment signaler les abus et faire entendre votre voix.",
    },
    {
      numero: "4",
      titre: "Téléphoner",
      description:
        "Sachez quand il est nécessaire d'appeler des professionnels pour obtenir de l'aide.",
    },
    {
      numero: "5",
      titre: "Porter plainte",
      description:
        "Apprenez les étapes pour porter plainte de manière formelle si nécessaire.",
    },
    {
      numero: "6",
      titre: "Soutenir",
      description:
        "Découvrez comment soutenir les autres et contribuer à un environnement plus sûr.",
    },
    {
      numero: "7",
      titre: "Ne pas négliger",
      description:
        "Comprenez pourquoi il est important de ne pas négliger les abus et les injustices.",
    },
    {
      numero: "8",
      titre: "En parler",
      description:
        "Apprenez l'importance de parler ouvertement des problèmes que vous rencontrez.",
    },
    {
      numero: "9",
      titre: "Ne pas participer",
      description:
        "Sachez quand il est essentiel de ne pas participer à des comportements inappropriés.",
    },
    {
      numero: "10",
      titre: "Convaincre",
      description:
        "Découvrez comment convaincre et éduquer les autres sur ces questions cruciales.",
},

   
  
   
  ];

}