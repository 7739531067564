
import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {

  loading:boolean = true;

  constructor(private loaderServive:SpinnerService){
    this.loaderServive.isLoading.subscribe((v:any)=>{
      setTimeout(()=>{this.loading = v},1500)
    })
  }

  ngOnInit(): void {
    
  }
}
