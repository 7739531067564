import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { DonationComponent } from "./donation/donation.component";
import { ActionPagesComponent } from "./action-pages/action-pages.component";
import { LuttePageComponent } from "./lutte-page/lutte-page.component";
import { PresentationUFRComponent } from "./presentation-ufr/presentation-ufr.component";
import { PresentationCAEGPComponent } from "./presentation-caegp/presentation-caegp.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home1",
    pathMatch: "full",
  },
  {
    path: "home1",
    component: HomeComponent,
  },
  {
    path: "donation",
    component: DonationComponent,
  },
  {
    path: "action",
    component: ActionPagesComponent,
  },
  {
    path: "lutte",
    component: LuttePageComponent,
  },
  {
    path: "presentation-UFHB",
    component: PresentationUFRComponent,
  },
  {
    path: "presentation-CAEGP",
    component: PresentationCAEGPComponent,
  },
 

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PortalRoutingModule {}
