<app-spinner></app-spinner>
<div class="groupUfr">

    <div class="head"  data-aos="fade-up" data-aos-duration="2000">
        <img class="image-UFHB" src="/assets/images/ufr2.png" alt="">

        <div class="contentTitle">
           <h2>UNIVERSITE DE COCODY</h2>
           <h6>Découvrir l'Université Felix Houphout Boigny</h6>
           <P>Voulez-vous faire partie de notre université? Voulez-vous en savoir plus sur nos programmes? Pour en savoir plus, consultez notre section programme.</P>
        </div>
    </div>

     <!-- code pour le discover -->
  <div class="discover">
        <div class="content" data-aos="fade-right" data-aos-duration="2500">
            <div class="titre">
               <img class="svges" src="/assets/images/blob.svg" alt=""> 
               <h2> Nous découvrir</h2>
            </div> <br>
           
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptates numquam provident deleniti iste. Fuga beatae iste ipsa, aperiam dicta explicabo unde impedit ullam alias sint praesentium velit hic architecto! Esse. Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias illum iste voluptas quam consectetur, suscipit fugit minima inventore nobis distinctio vitae aut omnis, assumenda temporibus cumque culpa nesciunt quod dolorem? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae assumenda harum nesciunt? Quod, corrupti amet quos voluptas velit dignissimos officia saepe enim facere quam soluta maxime quaerat nihil deserunt earum.</p>
        </div>
        <div class="imageDiscover"  data-aos="fade-up" data-aos-duration="3000">
            <img src="/assets/images/urDiscover.jpg" alt="">
        </div>
    </div> 


    <!-- code pour nos missions et valeurs -->

     <div class="assignement">
        <h2 class="" data-aos="fade-up-right" data-aos-duration="3000" >Missions & valeurs</h2>
        <p>Par essence ouverte sur le monde, l'université est le lieu de la connaissance, de l'innovation et de la transmission des savoirs.</p>


        <div class="multplyCard">
            <div class="carte" data-aos="fade-right" data-aos-duration="750" >
                <div class="icones"><img src="/assets/icons/innovation.png" alt=""></div>
                <div class="title"><h6> Lorem ipsum</h6></div>
            </div>
            <div class="carte" data-aos="fade-right" data-aos-duration="1500">
                <div class="icones"><img src="/assets/icons/innovation.png" alt=""></div>
                <div class="title"><h6> Lorem ipsum</h6></div>
            </div>
            <div class="carte" data-aos="fade-right" data-aos-duration="2250">
                <div class="icones"><img src="/assets/icons/innovation.png" alt=""></div>
                <div class="title"><h6>Lorem ipsum</h6></div>
            </div>
            <div class="carte" data-aos="fade-right" data-aos-duration="3000">
                <div class="icones"><img src="/assets/icons/innovation.png" alt=""></div>
                <div class="title"><h6>Lorem ipsum</h6></div>
            </div> 
        </div>

        <p class="valeur" data-aos="fade-up" data-aos-duration="2000">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Est quae totam ullam recusandae deleniti consequatur quos inventore cum molestias maiores itaque alias expedita, natus porro amet numquam culpa in atque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione, magnam consequatur? Aliquam suscipit neque ducimus saepe aperiam eius sapiente, dolores itaque vero facere! Officia aliquid esse odio doloremque adipisci eum!
        </p>

    </div>

    <div class="InNumbers">
        <h2 data-aos="fade-up-right" data-aos-duration="3000">En chiffres (données 2023)</h2>

        <div class="nombreTotal">
            
        <div class="donnes" data-aos="zoom-in-up" data-aos-duration="500">
            <h1>9 000</h1>
            <p>étudiantes et étudiants</p>
        </div>
        <div class="donnes"  data-aos="zoom-in-up" data-aos-duration="1000">
            <h1>600</h1>
            <p> Membres du personnels</p>
        </div>
        <div class="donnes"  data-aos="zoom-in-up" data-aos-duration="1500">
            <h1>90</h1>
            <p>structures de recherche</p>
        </div>
        <div class="donnes"  data-aos="zoom-in-up" data-aos-duration="2000">
            <h1>30</h1>
            <p>Filières</p>
        </div>

        <div class="donnes"  data-aos="zoom-in-up" data-aos-duration="2500">
            <h1>10</h1>
            <p>UFR</p>
        </div>
        <div class="donnes"  data-aos="zoom-in-up" data-aos-duration="3000">
            <h1>130</h1>
            <p>projets collaboratifs de recherche européens</p>
        </div>
        </div>
    </div>

    <div class="formation">
       <h2 data-aos="fade-up-right" data-aos-duration="2500">Les formations dispensées</h2>
        
        <div class="globFormations" data-aos="zoom-out-right" data-aos-duration="3000">
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
            <div class="groupCard">
                <div class="cards">
                  <h5>lorem ipsum</h5>
                </div>
            </div>
        </div>
        
    </div>

     <div class="actuality">
        <h2 data-aos-duration="3000">Actualité</h2>
        <h6>fils des actualités</h6> <br>

        <div class="filACtuality">
            <div class="actu" data-aos="fade-up" data-aos-duration="1000">
                <img src="/assets/images/cooperation_ufhb.jpeg" alt="">

                <h5>Nos actualités</h5>
                <div class="suite">
                    <p>lire la suite </p>
                    <p class="icones"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></p>
                </div>
                <hr>
            </div>
            <div class="actu" data-aos="fade-up" data-aos-duration="2000">
                <img src="/assets/images/university.jpg" alt="">

                <h5>Nos actualités</h5>
                <div class="suite">
                    <p>lire la suite </p>
                    <p class="icones"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></p>
                </div>
                <hr>
            </div>
            <div class="actu" data-aos="fade-up" data-aos-duration="3000">
                <img src="/assets/images/denonce.jpg" alt="">

                <h5>Nos actualités</h5>
                <div class="suite">
                    <p>lire la suite </p>
                    <p class="icones"><span nz-icon nzType="arrow-right" nzTheme="outline"></span></p>
                </div>
                <hr>
            </div>
        </div>

    </div> 
    
</div>