
<app-spinner></app-spinner>
<div class="presentationGlobal">
    <div class="head" data-aos="fade-up" data-aos-duration="2000">
        <img class="image-UFHB" src="/assets/images/ufr2.png" alt="">

        <div class="contentTitle" >
           <h2>Cellule de   Autonome de d'Ecoute et de Gestion des Plaintes (CAEGP).</h2>
           <h6>Présentation de ce que nous faisons.</h6>
           
        </div>
    </div>
    <div class="content">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus aspernatur, soluta impedit dicta expedita, quidem commodi ipsam repudiandae eos placeat tenetur laborum, sed pariatur provident consectetur quod quisquam sint qui. Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita consequuntur tempora atque ullam culpa suscipit officia aspernatur officiis a laudantium laboriosam, sit facere dicta eius eos laborum! Voluptate, totam ducimus?
    </div>

    <div class="mission">
        <h2 data-aos="fade-right" data-aos-duration="3000">NOS MISSIONS</h2>
        <p>Le centre d'écoute et de gestion des plaintes  exerce des missions de conseil et d’assistance , de prévention et de sanctions.</p> <br>
        <p>A ce titre, la CAEGP :</p>

        <div class="groupMission" >
            <div class="missionCard"  data-aos="fade-right" data-aos-duration="1000">
                <img src="/assets/images/promotion.png" alt="">

                <h6>Sensibilisations</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
            <div class="missionCard"  data-aos="fade-right" data-aos-duration="1300">
                <img src="/assets/icons/people.png" alt="">

                <h6>Accompagnement</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
            <div class="missionCard "  data-aos="fade-right" data-aos-duration="1600">
                <img src="/assets/icons/followers.png" alt="">

                <h6>Écoute et suivi </h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
            <div class="missionCard "  data-aos="fade-right" data-aos-duration="2000">
                <img src="/assets/icons/intervention.png" alt="">

                <h6>Intervention et résolution </h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
            <div class="missionCard "  data-aos="fade-right" data-aos-duration="2500">
                <img src="/assets/icons/evaluation.png" alt="">

                <h6>Suivi et évaluation</h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
            <div class="missionCard "  data-aos="fade-right" data-aos-duration="3000">
                <img src="/assets/icons/sanctions.png" alt="">

                <h6>Prises de sanctions </h6>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, illo dolores quas officiis iste corporis repudiandae! Nemo</p>
            </div>
        </div>

        <div class="cardTexte"  data-aos="fade-up" data-aos-duration="3000">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error totam id dolorem illum quaerat labore maiores sed adipisci facere nulla, enim nobis ipsa perspiciatis quibusdam ut tempora officiis. Fugiat, laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum alias delectus earum hic non illo! Perferendis nemo reprehenderit eum quisquam reiciendis libero praesentium? Dolorem ullam at officiis eveniet atque? Ut.
        </div>
        
    </div>

    <div class="organisation">
       <h2>Oraganisation</h2>

       <div class="listOrganozation">
            <div class="carte"  data-aos="flip-left" data-aos-duration="2500">
                <img src="/assets/images/user.png" alt=""> 
                <h5>Jhon Dohe Jonshon</h5>
                <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
            </div>

            <div class="secondNiveau">
                <div class="carte" data-aos="fade-right" data-aos-duration="2000">
                    <img src="/assets/images/user.png" alt=""> 
                    <h5>Jhon Dohe Jonshon</h5>
                    <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
                </div>
                <div class="carte" data-aos="fade-right" data-aos-duration="2500">
                    <img src="/assets/images/user.png" alt=""> 
                    <h5>Jhon Dohe Jonshon</h5>
                    <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
                </div>
                <div class="carte" data-aos="fade-right" data-aos-duration="3000">
                    <img src="/assets/images/user.png" alt=""> 
                    <h5>Jhon Dohe Jonshon</h5>
                    <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
                </div>
            </div>

            <div class="NiveauThree" data-aos="fade-up" data-aos-duration="3000">
                <div class="carte">
                    <img src="/assets/images/user.png" alt=""> 
                    <h5>Jhon Dohe Jonshon</h5>
                    <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
                </div>
                <div class="carte">
                    <img src="/assets/images/user.png" alt=""> 
                    <h5>Jhon Dohe Jonshon</h5>
                    <p>Lorem ipsum dolor, loremnipsum dolor uscipit officia aspernatur</p>
                </div>
               
            </div>
           
       </div>
    </div>

     <div class="sanctions">
        <h2>La commission des sanctions</h2>

        <div class="contenu">
            <div class="image" data-aos="fade-right" data-aos-duration="3000"><img src="/assets/images/sanctions.jpg" alt=""></div>
            <div class="text" data-aos="fade-up" data-aos-duration="2800">
                <h5>Le centre autonome d'écoute et de gestion de plaintes  comprend une commission des sanctions chargée de prononcer  </h5>
               <div class="flexe">
                <div class="check">
                    <img src="/assets/images/check2.png" alt="">
                    <p>lorem ipsum dolor saterp lorem</p>
                </div>
                <div class="check">
                    <img src="/assets/images/check2.png" alt="">
                    <p>lorem ipsum dolor saterp lorem</p>
                </div>
               </div>
               <div class="flexe">
                <div class="check">
                    <img src="/assets/images/check2.png" alt="">
                    <p>lorem ipsum dolor saterp lorem</p>
                </div>
                <div class="check">
                    <img src="/assets/images/check2.png" alt="">
                    <p>lorem ipsum dolor saterp lorem</p>
                </div>
               </div>

            </div>
        </div>

    </div> 
</div>
