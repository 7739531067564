<div class="header-container">
    <nav>
      <a href="#" class="nav-icon" aria-label="homepage" aria-current="page">
        <img src="/assets/images/logo-app.svg" alt="logo-CAEGP" />
      </a>
      <div class="main-navlinks">
        <button
          type="button"
          (click)="toggleNav()"
          class="hamburger"
          #hamburger
          aria-label="Toggle Navigation"
          aria-expanded="false"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="navlinks-container" #navLinksContainer>
          <a
            routerLink="/portal/home"
            routerLinkActive="active"
            (click)="goToHome()"
            aria-current="page"
            >Accueil</a
          >
  
          <a
            routerLink="/portal/action"
            routerLinkActive="active"
            (click)="goToAction()"
            >Nos actions</a
          >
          <a
            routerLink="/portal/lutte"
            routerLinkActive="active"
            (click)="goToLutte()"
            >Comment lutter</a
          >
          <a nz-dropdown [nzDropdownMenu]="menu"
            routerLinkActive="active"
            >Présentation  <i nz-icon nzType="down"></i></a
          >
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li nz-menu-item routerLink="/portal/presentation-UFHB">Présentation de l'UFHB</li>
              <li nz-menu-item routerLink="/portal/presentation-CAEGP">Présentation de la CAEGP</li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </div>
      <div class="nav-authentication">
        <!-- <a href="#" class="user-toggler" aria-label="Sign in page">
                <img src="/assets/images/user.svg"/>
              </a> -->
        <div class="sign-btns">
          <button (click)="goToDon()" type="button">Faire un don</button>
        </div>
      </div>
      <img class="logo-UFHB" src="/assets/images/logo-ufhb.png" alt="logo-UFHB" />

    </nav>
  </div>
  