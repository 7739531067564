import {Component} from '@angular/core';

@Component({
  selector: 'footer-cmp',
  templateUrl: 'footer.component.html'
})

export class FooterComponent {
  date: Date = new Date();
}
