import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import * as AOS from "aos";
import { OwlOptions } from "ngx-owl-carousel-o";

interface carousselImage {
  imageSrc: string;
  imageAlt: string;
}
@Component({
  selector: "app-app-slider",
  templateUrl: "./app-slider.component.html",
  styleUrls: ["./app-slider.component.less"],
})
export class AppSliderComponent implements OnInit, AfterViewInit {
  @Input("images") images: carousselImage[] = [];

  constructor(private renderer: Renderer2) {}

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: [
      '<i class="fa fa-arrow-left custom-prev"></i>',
      '<i class="fa fa-arrow-right custom-next" ></i>',
    ],
    items: 2,
    nav: true,
    responsive: {
      0: {
        items: 1,
      },

      768: {
        items: 3,
      },
    },
  };

  ngOnInit() {
    AOS.init();
  }

  availableImage(index: number) {
    const initialIndex = [0, 1, 2, 3];
    const elementAvailable = initialIndex.includes(index);
  }

  ngAfterViewInit(): void {
    const items = document.querySelectorAll("img");
    const nbSlides = items.length;
    const suivant = document.querySelector(".right");
    const precedent = document.querySelector(".left");
    let count = 0;
    function slideSuivante() {
      items[count].classList.remove("active");
      if (count < nbSlides - 1) {
        count++;
      } else {
        count = 0;
      }

      items[count].classList.add("active");
    }

    suivant?.addEventListener("click", slideSuivante);
  }

  addActiveClass(index: number) {
    // if (this.elementLastActiveIndex + 2 > this.imageLastIndex!) {
    //   return;
    // }
  }
}
