import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { mappingRoutes } from "src/app/core/mappingRoutes";

@Component({
  selector: 'app-portal-header',
  templateUrl: './portal-header.component.html',
  styleUrls: ['./portal-header.component.less']
})
export class PortalHeaderComponent implements OnInit {
  ismobileOpen: boolean = false;

  @ViewChild("hamburger", { static: false }) toggler: ElementRef | undefined;
  @ViewChild("navLinksContainer", { static: false }) navLinksContainer:
    | ElementRef
    | undefined;

  ngOnInit(): void {}
  constructor(private router: Router, private renderer: Renderer2) {}

  ngAfterViewInit() {}
  toggleNav() {
    if (this.toggler && this.navLinksContainer) {
      // Animation du bouton

      this.renderer.addClass(this.toggler.nativeElement, "open");

      const ariaToggle =
        this.toggler.nativeElement.getAttribute("aria-expanded") === "true"
          ? "false"
          : "true";
      this.ismobileOpen = !this.ismobileOpen;
      console.log(this.toggler.nativeElement.getAttribute("aria-expanded"));

      if (this.ismobileOpen) {
        this.renderer.setAttribute(
          this.toggler.nativeElement,
          "aria-expanded",
          ariaToggle
        );

        // Slide de la navigation
        this.renderer.addClass(this.navLinksContainer.nativeElement, "open");
      } else {
        this.removeSideBar();
      }
    }
  }

  removeSideBar() {
    const ariaToggle =
      this.toggler?.nativeElement.getAttribute("aria-expanded") === "true"
        ? "false"
        : "true";
    this.renderer.removeAttribute(this.toggler?.nativeElement, "aria-expanded");
    // Slide de la navigation
    this.renderer.removeClass(this.navLinksContainer?.nativeElement, "open");
    this.renderer.removeClass(this.toggler?.nativeElement, "open");
    this.toggler?.nativeElement.setAttribute("aria-expanded", false);
  }

  isActiveRoute(pathName: string): string {
    return this.router.isActive(pathName, true) ? "active" : "";
  }

  goToHome() {
    this.router.navigate([mappingRoutes.portal.home]);
    this.removeSideBar();
  }
  goToLutte() {
    this.router.navigate([mappingRoutes.portal.lutte]);
    this.removeSideBar();
  }

  goToAction() {
    this.router.navigate([mappingRoutes.portal.actions]);
    this.removeSideBar();
  }

  goToDon() {
    this.router.navigate([mappingRoutes.portal.donation]);
    this.removeSideBar();
  }
}
