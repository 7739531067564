<nav
  class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent flex-column"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button (click)="sidebarToggle()" class="navbar-toggler" type="button">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{
        this.titleModule
      }}</a>
    </div>
    <button
      (click)="collapse()"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="collapseExample"
      class="navbar-toggler"
      type="button"
    >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div
      [ngbCollapse]="isCollapsed"
      class="collapse navbar-collapse justify-content-end"
      id="collapseExample"
    >
      <form>
        <div>
          <nz-input-group
            [nzSearch]="true"
            nzPrefixIcon="search"
            class="custom-input-group"
            nzSize="default"
          >
            <input
              nz-input
              nzSize="default"
              placeholder="Rechercher"
              type="search"
            />
          </nz-input-group>
        </div>
      </form>
      <ul class="navbar-nav align-item-center">
        <li class="nav-item cursor-pointer" (click)="goNotification()">
          <div class="notification-icon bg-white">
            <i class="nc-icon nc-bell-55"></i>
          </div>
          <div class="badge_notification">+10</div>
        </li>

        <li class="nav-item btn-rotate" ngbDropdown placement="bottom-left">
          <a
            class="nav-link avatar-user mr-1"
            id="navbarDropdownMenuLink"
            ngbDropdownToggle
          >
            <nz-space
              class="mr-1"
              nzSize="middle"
              nzDirection="horizontal"
              nzWrap
            >
              <nz-avatar
                *nzSpaceItem
                nzSize="small"
                nzShape="circle"
                nzIcon="user"
              ></nz-avatar>
              <p *nzSpaceItem class="m-0">
                <span class="d-md-block">Evan Yate</span>
              </p>
            </nz-space>
          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu dropdown-menu-right"
            ngbDropdownMenu
          >
            <a (click)="goProfile()" ngbDropdownItem>Mon profile</a>
            <a href="javascript:void(0)" ngbDropdownItem>Déconnexion</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="container-fluid pb-2">
    <div class="row">
      <div class="col-sm-12">
        <nz-breadcrumb nzAutoGenerate nzSeparator="/"></nz-breadcrumb>
      </div>
    </div>
  </div>
</nav>
