import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-portal',
  templateUrl: './layout-portal.component.html',
  styleUrls: ['./layout-portal.component.less']
})
export class LayoutPortalComponent {

  

}
