<div class="slide-container" style="height: 400px">
  <owl-carousel-o [options]="customOptions">
    <ng-container *ngFor="let slide of images; let i = index">
      <ng-template carouselSlide [id]="'id_' + i">
        <div
          class="image_preview"
          [ngStyle]="{
            'background-image': 'url(' + slide.imageSrc + ')'
          }"
        ></div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
