
import { Component, OnInit,Input } from '@angular/core';
import * as AOS from 'aos'

@Component({
  selector: 'app-titre',
  templateUrl: './titre.component.html',
  styleUrls: ['./titre.component.less']
})
export class TitreComponent implements OnInit {

  
  ngOnInit(): void {
    AOS.init();
  }

  
  @Input('title') title: string = '';
  @Input('preTitle') preTitle: string = '';
  @Input('description') description: string = '';
}
