import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppComponent } from "./app.component";
import { fr_FR, NZ_I18N } from "ng-zorro-antd/i18n";
import { registerLocaleData } from "@angular/common";
import fr from "@angular/common/locales/fr";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { NzImageService } from "ng-zorro-antd/image";
import { ngZorroConfig } from "./core/ngZorroConfig";
import { NZ_CONFIG } from "ng-zorro-antd/core/config";
import { RouterModule } from "@angular/router";
import { AppRoutes } from "./app.routing";
import { NzFormModule } from "ng-zorro-antd/form";
import { ImageCropperModule } from "ngx-image-cropper";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { LayoutsModule } from "./layouts/layouts.module";
import { PortalModule } from "./pages/portal/portal.module";

registerLocaleData(fr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false,
      scrollOffset: () => [0, 0],
      scrollPositionRestoration: "top",
    }),
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NzFormModule,
    ImageCropperModule,
    NzDividerModule,
    NzButtonModule,
    NzSpinModule,
    LayoutsModule,
    PortalModule,
   
  ],
  providers: [
    { provide: NZ_I18N, useValue: fr_FR },
    { provide: NZ_CONFIG, useValue: ngZorroConfig },
    NzMessageService,
    NzNotificationService,
    NzModalService,
    NzDrawerService,
    NzImageService,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
