
import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { mappingRoutes } from "src/app/core/mappingRoutes";


@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.less']
})
export class PortalFooterComponent {
  @ViewChild("hamburger", { static: false }) toggler: ElementRef | undefined;
  @ViewChild("navLinksContainer", { static: false }) navLinksContainer:
    | ElementRef
    | undefined;
  ismobileOpen: boolean=false;

  slideConfig = {"slidesToShow": 3,
   "slidesToScroll": 3,
      dots:true ,
     autoplay:true,
     pauseOnHover:true,
     autoplaySpeed:4000,
     infinite:true,
     "responsive":[
      {
        breakpoint:992,
        settings: {
          "arrows":true,
         " infinite":true,
          "slidesToShow": 2,
          "slidesToScroll": 2,

        }
      },
      {
        breakpoint:600,
        settings: {
          "arrows":true,
         " infinite":true,
          "slidesToShow": 1,
          "slidesToScroll": 1,

        }
      }
     ]
};

  Images = [
    {
      src:'/assets/images/partenaire1.jpg',
      name:'Kouassi jules',
    },
    {
      src:'/assets/images/partenaire2.jpg',
      name:'Kouassi jules',
    },
    {
      src:'/assets/images/partenaire3.jpg',
      name:'Kouassi jules',
    },
    {
      src:'/assets/images/partenaire4.jpg',
      name:'Kouassi jules',
    },
   
   
  ]


  constructor(private router: Router, private renderer: Renderer2) {}

  ngAfterViewInit() {}
  toggleNav() {
    if (this.toggler && this.navLinksContainer) {
      // Animation du bouton

      this.renderer.addClass(this.toggler.nativeElement, "open");

      const ariaToggle =
        this.toggler.nativeElement.getAttribute("aria-expanded") === "true"
          ? "false"
          : "true";
      this.ismobileOpen = !this.ismobileOpen;
      console.log(this.toggler.nativeElement.getAttribute("aria-expanded"));

      if (this.ismobileOpen) {
        this.renderer.setAttribute(
          this.toggler.nativeElement,
          "aria-expanded",
          ariaToggle
        );

        // Slide de la navigation
        this.renderer.addClass(this.navLinksContainer.nativeElement, "open");
      } else {
        this.removeSideBar();
      }
    }
  }

  removeSideBar() {
    const ariaToggle =
      this.toggler?.nativeElement.getAttribute("aria-expanded") === "true"
        ? "false"
        : "true";
    this.renderer.removeAttribute(this.toggler?.nativeElement, "aria-expanded");
    // Slide de la navigation
    this.renderer.removeClass(this.navLinksContainer?.nativeElement, "open");
    this.renderer.removeClass(this.toggler?.nativeElement, "open");
    this.toggler?.nativeElement.setAttribute("aria-expanded", false);
  }

  isActiveRoute(pathName: string): string {
    return this.router.isActive(pathName, true) ? "active" : "";
  }

  goToHome() {
    this.router.navigate([mappingRoutes.portal.home]);
    this.removeSideBar();
  }
  goToLutte() {
    this.router.navigate([mappingRoutes.portal.lutte]);
    this.removeSideBar();
  }

  goToAction() {
    this.router.navigate([mappingRoutes.portal.actions]);
    this.removeSideBar();
  }

  goToDon() {
    this.router.navigate([mappingRoutes.portal.donation]);
    this.removeSideBar();
  }
}