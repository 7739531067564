import { Component, OnInit } from "@angular/core";
import * as AOS from "aos";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-action-pages",
  templateUrl: "./action-pages.component.html",
  styleUrls: ["./action-pages.component.less"],
})
export class ActionPagesComponent implements OnInit {
  ngOnInit(): void {
    AOS.init();
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ["Précédent", "Suivant"],
    items: 3,
    nav: true,
  };
}
