<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div
          class="copyright justify-content-center align-item-center w-inherit text-center"
        >
          CAMPUS &copy; {{ date | date : "yyyy" }} copyright tout droit réservé
        </div>
      </div>
    </div>
  </div>
</footer>
