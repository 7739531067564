import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PortalRoutingModule } from "./portal-routing.module";
import { HomeComponent } from "./home/home.component";
import { AppSliderComponent } from "./component/app-slider/app-slider.component";
import { NzIconModule } from "ng-zorro-antd/icon";
import { DonationComponent } from "./donation/donation.component";
import { ActionPagesComponent } from "./action-pages/action-pages.component";
import { LuttePageComponent } from "./lutte-page/lutte-page.component";
import { HeroComponent } from "./home/component/hero/hero.component";
import { TitreComponent } from "./component/app-slider/titre/titre.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { SlidersActionsComponent } from "./component/app-slider/sliders-actions/sliders-actions.component";
import { RouterLinkActive } from "@angular/router";
import { PresentationUFRComponent } from './presentation-ufr/presentation-ufr.component';
import { PresentationCAEGPComponent } from './presentation-caegp/presentation-caegp.component';
import { SlickCarouselModule } from "ngx-slick-carousel";
import { SpinnerComponent } from './spinner/spinner.component';
import { LayoutsModule } from "src/app/layouts/layouts.module";

@NgModule({
  declarations: [
    HomeComponent,
    AppSliderComponent,
    DonationComponent,
    ActionPagesComponent,
    LuttePageComponent,
    HeroComponent,
    TitreComponent,
    SlidersActionsComponent,
    PresentationUFRComponent,
    PresentationCAEGPComponent,
    SpinnerComponent,
    
    
  ],
  imports: [
    CommonModule,
    PortalRoutingModule,
    NzIconModule,
    CarouselModule,
    RouterLinkActive,
    SlickCarouselModule,

  ],
  exports:[
    SpinnerComponent,
  ]
})
export class PortalModule {}
