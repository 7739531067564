<!-- <ng-container>
    <div id="pre-loader" >
      <div class="center">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <div id="typing">{{ currentPhrase }}</div>
      </div>
    </div>
  </ng-container> -->

  <div *ngIf="loading" id="preloder" class=" snipet flex flex-auto flex-col items-center justify-center p-8">
    <div class="spinner">
      <img class="rotation" src="/assets/images/logo-app.svg" alt="chat icon" />
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
    <div class=" loading mt-4 text-1xl font-semibold tracking-tight text-secondary">CAEGP...</div>
</div>
