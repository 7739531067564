import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-presentation-caegp',
  templateUrl: './presentation-caegp.component.html',
  styleUrls: ['./presentation-caegp.component.less']
})
export class PresentationCAEGPComponent implements OnInit {

  ngOnInit(): void {
    AOS.init();
  }
}
